import axios from 'axios'
import { interceptor } from '@/application/utils/interceptor.js'

const domain = process.env.VUE_APP_API_PATH
const getNotificationSettingsApi = `${domain}api/v1/dashboard/balance-notifications-settings-data/`
const getSettingsPlansSubscriptionsApi = (planType) => `${domain}api/v1/dashboard/balance-notifications-settings/?plan_type=${planType}`

export const saveNotificationParams = (data) => interceptor(axios).patch(getNotificationSettingsApi, data)
export const deleteContactFromNotifications = (data) => interceptor(axios).delete(getNotificationSettingsApi, { data })
export const saveNotificationSettings = (data) => interceptor(axios).post(getNotificationSettingsApi, data)
export const getNotificationSettings = () => interceptor(axios).get(getNotificationSettingsApi)
export const getSettingsPlansSubscriptions = (planType) => interceptor(axios).get(getSettingsPlansSubscriptionsApi(planType))
