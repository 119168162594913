import axios from 'axios'
import { interceptor } from '@/application/utils/interceptor.js'

const domain = process.env.VUE_APP_API_PATH

const integrationsInfoApi = `${domain}api/v1/integrations/list/`
const integrationsCredentialsApi = `${domain}api/v1/integrations/set-credentials/`
const integrationsSettingsApi = `${domain}api/v1/integrations/set-settings/`
const integrationsConnectionRemoveApi = (id) => `${domain}api/v1/integrations/delete/${id}`
const senderNameIntegrationsSettingsApi = (id) => `${domain}api/v1/dashboard/request/sender-name/${id}/`

export const getIntegrationsDataApi = () => interceptor(axios).get(integrationsInfoApi)
export const sendIntegrationsCredentialsApi = (data) => interceptor(axios).post(integrationsCredentialsApi, data)
export const sendIntegrationsSettingsApi = (data) => interceptor(axios).post(integrationsSettingsApi, data)
export const sendIntegrationsConnectionRemoveApi = (id) => interceptor(axios).post(integrationsConnectionRemoveApi(id))
export const sendSenderNameIntegrationsSettingsApi = (data, id) => interceptor(axios).post(senderNameIntegrationsSettingsApi(id), data)
