import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { trimValues } from '@/application/utils/trim.js'
import { saveNotificationSettings } from '@/application/services/notification-settings.js'
import { useModal } from '@/application/composables/modal.js'
import { getLocalizedOrValue } from '@/application/utils/localization'

export function useNotificationSettingsLogic() {
  const i18n = useI18n()
  const isDisabled = ref(false)
  const errorMessage = ref('')
  const { openModal } = useModal()

  const formNotificationState = ref({})
  const submitFormNotificationState = (data) => {
    formNotificationState.value = data
  }
  const submitNotificationSettingsButton = computed(() => ({
    wrapper: 'justify-content-start',
    class: 'btn btn-primary mb-4 mt-4',
    text: i18n.t('save'),
  }))
  const formSchemaAutoCharge = computed(() => ({
    subscription: {
      label: i18n.t('fromSubscription'),
      name: 'subscription',
      as: 'select',
      type: 'text',
      rules: 'required',
      cols: 'col-12 col-md-6',
      inputClass: 'form-control',
      fieldClass: 'form-group',
      description: i18n.t('notificationSubscriptionFieldDescription'),
    },
    plan: {
      label: i18n.t('additionalRequestPackage'),
      name: 'plan',
      as: 'select',
      type: 'text',
      rules: 'required',
      cols: 'col-12 col-md-6',
      inputClass: 'form-control',
      fieldClass: 'form-group',
    },
  }))
  const getFormSchemaAutoCharge = (formSchema, subscriptions, plans) => {
    formSchema.subscription.children = subscriptions.map(packageElement => ({
      tag: 'option',
      text: `${packageElement.payment_info.payment_method === 'card' ? `${getLocalizedOrValue(i18n, `paymentMethod.${packageElement.payment_info.payment_method}`, packageElement.payment_info.payment_method)}: ${packageElement.payment_info.card_type} ${packageElement.payment_info.last_four_digits} - ${packageElement.payment_info.expiry_date} | ${packageElement.requests} ${i18n.t('requests')}` : `${getLocalizedOrValue(i18n, `paymentMethod.${packageElement.payment_info.payment_method}`, packageElement.payment_info.payment_method)}`}`,
      value: packageElement.id,
    }))
    formSchema.plan.children = plans.map(plan => ({
      tag: 'option',
      text: `${i18n.t('requests')}: ${plan.messages} - ${plan.localized_price}`,
      value: plan.package_id,
    }))
  }
  const submitAutoChargeButton = computed(() => ({
    wrapper: 'justify-content-start',
    class: 'btn btn-primary mb-4 mt-4',
    text: i18n.t('save'),
  }))
  const submitAutoCharge = (value, { resetForm }) => {
    isDisabled.value = true
    const formatted = trimValues(value)
    console.log(formatted)
  }
  const formSchema = computed(() => [
    {
      label: i18n.t('addNotificationContact'),
      name: 'email',
      as: 'input',
      type: 'text',
      rules: 'required|email',
      cols: ' col-12',
      inputClass: 'form-control',
      description: i18n.t('notificationEmailFieldDescription'),
    },
  ])
  const submitButton = computed(() => ({
    wrapper: 'justify-content-start',
    class: 'btn btn-primary mb-4 mt-4',
    text: i18n.t('addRecipient'),
  }))
  const submit = (value, { resetForm }) => {
    isDisabled.value = true
    resetForm()
  }
  const saveConversationNotificationHandler = (data, typeName) => {
    saveNotificationSettings({ ...data, type: typeName }).then(() => {
      openModal(i18n.t('success'), i18n.t('successSaving'))
    }).catch(err => {
      console.log(err)
    })
  }
  return {
    formSchema,
    submitButton,
    submit,
    isDisabled,
    errorMessage,
    formSchemaAutoCharge,
    getFormSchemaAutoCharge,
    submitAutoChargeButton,
    submitAutoCharge,
    submitFormNotificationState,
    submitNotificationSettingsButton,
    formNotificationState,
    saveConversationNotificationHandler,
  }
}
