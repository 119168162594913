import { computed, ref } from 'vue'
import { useModal } from '@/application/composables/modal'
import { useI18n } from 'vue-i18n'
import { sendSenderNameIntegrationsSettingsApi } from '@/application/services/integrations.js'
import { getLocalizedServerError } from '@/application/utils/localization'

export const useIntegrationForm = (senderNameId) => {
  const i18n = useI18n()
  const { openModal } = useModal()
  const integrationVendor = ref('')
  const isIntegrationFormLoading = ref(false)
  const modalIntegrationSettings = ref(null)
  const integrationFormButton = computed(() => ({
    wrapper: 'justify-content-center',
    class: 'btn btn-primary shadow-2 mt-4',
    text: i18n.t('integrations.form.settings.sendButton'),
  }))
  const integrationFormSchema = ref({})
  const integrationFormInitialData = ref({})

  const getFieldType = (type) => {
    if (type === 'bool') {
      return 'checkbox'
    } else if (type === 'str') {
      return 'text'
    } else {
      return 'number'
    }
  }

  const getIntegrationForm = (fields) => {
    const formSchemaFields = {}
    const initialData = {}

    fields.forEach(field => {
      const rules = []
      if (field.required) {
        rules.push('required')
      }

      if (field.max_value) {
        if (field.type === 'str' || field.type === 'text') {
          rules.push(`max:${field.max_value}`)
        } else if (field.type !== 'bool') {
          rules.push(`max_value:${field.max_value}`)
        }
      }

      if (field.min_value) {
        if (field.type === 'str' || field.type === 'text') {
          rules.push(`min:${field.min_value}`)
        } else if (field.type !== 'bool') {
          rules.push(`min_value:${field.min_value}`)
        }
      }

      formSchemaFields[field.name] = {}
      formSchemaFields[field.name].label = i18n.t(`integrations.fields.${field.name}`)
      formSchemaFields[field.name].name = field.name
      formSchemaFields[field.name].type = getFieldType(field.type)
      formSchemaFields[field.name].as = field.type === 'text' ? 'textarea' : 'input'
      formSchemaFields[field.name].rules = rules.join('|')
      formSchemaFields[field.name].cols = 'col-12'
      formSchemaFields[field.name].inputClass = 'form-control'
      formSchemaFields[field.name].fieldClass = 'form-group'
      formSchemaFields[field.name].value = field.value
      formSchemaFields[field.name].validateOnInput = false
      formSchemaFields[field.name].description = i18n.t(`integrations.fields_description.${field.name}`)
      formSchemaFields[field.name].isHTMLDescription = true
      initialData[field.name] = field.value
    })
    integrationFormSchema.value = formSchemaFields
    integrationFormInitialData.value = initialData
  }

  const removeIntegrationConnectionHandler = (vendor) => {
    const data = {
      integration: {
        vendor: vendor,
        is_connected: false,
      },
    }
    sendSenderNameIntegrationsSettingsApi(data, senderNameId.value)
      .then(() => {
        isIntegrationFormLoading.value = false
        openModal(i18n.t('success'), 'Removed successfully', () => {
          window.location.reload()
        })
      })
      .catch(err => {
        const response = err.response
        openModal(i18n.t('error'), getLocalizedServerError(i18n, 'integrations.errors.', response))
        isIntegrationFormLoading.value = false
      })
  }

  const integrationFormSendHandler = (data) => {
    isIntegrationFormLoading.value = true
    const formatted = data
    formatted.vendor = integrationVendor.value
    formatted.is_connected = true
    sendSenderNameIntegrationsSettingsApi({ integration: formatted }, senderNameId.value)
      .then(() => {
        isIntegrationFormLoading.value = false
        closeCredentialsModal()
        openModal(i18n.t('success'), 'Saved successfully', () => {
          window.location.reload()
        })
      })
      .catch(err => {
        const response = err.response
        openModal(i18n.t('error'), getLocalizedServerError(i18n, 'integrations.errors.', response))
        isIntegrationFormLoading.value = false
      })
  }

  const closeCredentialsModal = () => {
    integrationVendor.value = ''
    integrationFormSchema.value = {}
    integrationFormInitialData.value = {}
    modalIntegrationSettings.value.close()
  }

  return {
    getIntegrationForm,
    isIntegrationFormLoading,
    integrationFormSchema,
    modalIntegrationSettings,
    integrationFormInitialData,
    integrationFormButton,
    integrationFormSendHandler,
    removeIntegrationConnectionHandler,
    openIntegrationFormModal: (fields, vendor) => {
      integrationVendor.value = vendor
      getIntegrationForm(fields)
      setTimeout(() => {
        modalIntegrationSettings.value.open()
      }, 200)
    },
    closeCredentialsModal,
  }
}
